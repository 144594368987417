<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3">
      <h1 class="m-0 mb-2">System Settings</h1>
    </div>

    <div class="card table-container border-lg-0">
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Security Settings</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in securitysettingsList" :key="item.id">
            <td>{{ item.name }}</td>

            <td class="text-right">
              <router-link
                :to="'/admin/settings/' + item.routeName"
                class="btn btn-sm btn-outline-blue"
              >
                Open
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card table-container border-lg-0">
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>General Settings</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in generalSettingsList" :key="item.id">
            <td>{{ item.name }}</td>

            <td class="text-right">
              <router-link
                :to="'/admin/settings/' + item.routeName"
                class="btn btn-sm btn-outline-blue"
              >
                Open
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Settings",
  data() {
    return {
      securitysettingsList: [
        {
          name: "Permssions",
          routeName: "security/permissions",
        },

        {
          name: "Users Roles",
          routeName: "security/users-roles",
        },
      ],

      generalSettingsList: [
        {
          name: "Company Profile",
          routeName: "general/profile",
        },
        {
          name: "Online Booking Settings",
          routeName: "general/online-booking-settings",
        },
        {
          name: "Stripe Config",
          routeName: "general/stripe-config",
        },
      ],
    };
  },
};
</script>
